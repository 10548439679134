import styles from './Page.module.scss'

interface PageProps {
    children?: React.JSX.Element | React.JSX.Element[] | string | string[] | null | undefined
    className?: string
}

export default function Page(props: PageProps) {
    return (
        <main className={styles.content}>
            {props.children}
        </main>
    )
}