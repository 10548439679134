import { Tabs } from "react-daisyui";
import styles from './Fuentes.module.scss'
import Oil from "./components/Oil";
import Gas from "./components/Gas";


export default function Fuentes() {
    return (
        <div className={styles.fuentes_wrapper}>
            <div style={{ height: '100%', overflow: 'hidden' }}>
                <Tabs variant="bordered" size="lg">
                    <Tabs.RadioTab name="tabs" label="Gas" contentClassName="bg-base-100 p-6" defaultChecked>
                        <Gas />
                    </Tabs.RadioTab>
                    <Tabs.RadioTab name="tabs" label="Petróleo" contentClassName="bg-base-100 p-6">
                        <Oil />
                    </Tabs.RadioTab>
                </Tabs>
            </div>
        </div>
    )
}