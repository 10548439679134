import { useEffect, useRef, useState } from "react";
import { Button, Modal, Pagination, Select, Table } from "react-daisyui";
import { useParams } from "react-router-dom";

import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import styles from './Trabajos.module.scss';
import axios from "../../api/axios";

type History = {
	id: number | null;
	job_id: number | null;
	status: string | null;
	result: object | null;
	error: object | null;
	started_at: string | null;
	ended_at: string | null;
}

export default function TrabajosDetalle() {
	const ref = useRef<HTMLDialogElement>(null);

	const { id } = useParams();

	const [history, setHistory] = useState<Array<History>>([]);
	const [selected, setSelected] = useState<History | null>(null);
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(10)
	const [page, setPage] = useState(1)

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		const totalPages = Math.ceil(total / limit);
		const maxPage = Math.min(totalPages, maxPageButtons);
		const middlePage = Math.ceil(maxPage / 2);
		const startPage = Math.max(1, page - middlePage);
		const endPage = Math.min(totalPages, startPage + maxPage - 1);

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(
				<Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
					{i}
				</Button>
			);
		}

		return pageNumbers;
	}

	useEffect(() => {
		const downloadJobs = async () => {
			axios.get(`/api/jobs/${id}/history?offset=` + (page - 1) * limit + "&limit=" + limit).then((response) => {
				setHistory(response.data.data);
				setTotal(response.data.total);
			});
		}

		downloadJobs();
	}, [page, limit, id])

	const detail = (h: History) => {
		setSelected(h);
		ref.current?.showModal();
	}

	return (
		<>
			<div className={styles.trabajos_wrapper}>
				<div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					<Table zebra>
						<Table.Head>
							<span />
							<span>Estado</span>
							<span>Iniciado</span>
							<span>Terminado</span>
							<span>Total</span>
							<span>Acciones</span>
						</Table.Head>

						<Table.Body>
							{history && history.map((h, i) => (
								<Table.Row hover key={i}>
									<span>{h.id}</span>
									<span>{h.status}</span>
									<span>{h.started_at && new Date(h.started_at).toLocaleString('es-AR', { hour12: false })}</span>
									<span>{h.ended_at && new Date(h.ended_at).toLocaleString('es-AR', { hour12: false })}</span>
									<span>{h.ended_at && h.started_at && new Date(new Date(h.ended_at).getTime() - new Date(h.started_at).getTime()).toISOString().substr(11, 8)}</span>
									<span>
										<Button size="sm" shape="square" color="info" onClick={() => detail(h)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
												<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.07-.122.15-.195.232-.26.29-.63.668-1.07 1.043C11.879 11.332 10.12 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.133 13.133 0 0 1 1.173 8z" />
												<path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
											</svg>
										</Button>
									</span>
								</Table.Row>
							))}
						</Table.Body>
					</Table>

				</div>
				<div className={styles.pagination}>
					<div className={styles.cantidad}>
						<small >Cantidad por página:</small>
						<Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={20}>20</option>
						</Select>
					</div>
					<Pagination className="flex justify-center">
						<Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
							Anterior
						</Button>
						{renderPageNumbers()}
						<Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
							Siguiente
						</Button>
					</Pagination>
				</div>
			</div>

			<Modal ref={ref} backdrop={true}>
				<form method="dialog">
					<Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }}>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
					</Button>
				</form>
				<Modal.Header className="font-bold">Información</Modal.Header>
				<Modal.Body>
					{selected?.error && (
						<div className="bg-red-100 text-red-700 p-4 rounded">
							<JsonView data={selected.error} shouldExpandNode={allExpanded} style={defaultStyles} />
						</div>
					)}

					{selected?.result && (
						<div className="bg-green-100 text-green-700 p-4 rounded">
							<JsonView data={selected.result} shouldExpandNode={allExpanded} style={defaultStyles} />
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	)
}