import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting';
import { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import styles from './Main.module.scss'

// Inicializar el módulo de exportación
if (typeof Highcharts === 'object') {
	HighchartsExporting(Highcharts);
}

type NaturalAssociatedGasProductionData = {
	anio: number,
	mes: number,
	id_area_yacimiento: string,
	area_yacimiento: string,
	diaria: string,
}

interface DepositItem {
	area_yacimiento: string;
	anio: number;
	mes: number;
	diaria: number;
}

interface Deposits {
	[key: string]: DepositItem[];
}

interface NaturalAssociatedGasProductionProps {
	wFull?: boolean
}

export default function NaturalAssociatedGasProduction(props: NaturalAssociatedGasProductionProps) {
	Highcharts.setOptions({
		lang: {
			months: [
				'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
				'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
			],
			shortMonths: [
				'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
				'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
			],
			decimalPoint: ",",
			loading: 'Cargando...',
			viewFullscreen: "Ver en pantalla completa",
			printChart: "Imprimir gráfico",
			downloadPNG: "Descargar imagen PNG",
			downloadJPEG: "Descargar imagen JPEG",
			downloadPDF: "Descargar documento PDF",
			downloadSVG: "Descargar imagen SVG",
		}
	})

	const [data, setData] = useState<Array<NaturalAssociatedGasProductionData>>([])
	const [options, setOptions] = useState<Highcharts.Options>({
		colors: ['#e2ab60', '#105572', '#8D8D8D', '#6196b0', '#676472', '#197496'],
		chart: {
			backgroundColor: '#252b33',
			type: 'area'
		},
		accessibility: {
			enabled: false
		},
		exporting: {
			enabled: true // Habilitar la exportación
		},
		title: {
			text: 'Producción de gas natural asociado',
			align: 'left',
			style: {
				color: 'currentColor',
				font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
			}
		},
		xAxis: {
			labels: {
				style: {
					color: '#a6adbb',
				},
			},
		},
		yAxis: {
			title: {
				text: 'Millones de m3 por día',
				style: {
					color: 'currentColor',
					font: 'bold 14px "Trebuchet MS", Verdana, sans-serif'
				}
			},
			labels: {
				style: {
					color: 'currentColor'
				}
			}
		},
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true,
					format: '{point.y:.0f}'
				}
			},
			area: {
				stacking: 'normal' // Apilar las áreas
			}
		},
		tooltip: {
			backgroundColor: '#333333',  // Color del fondo del tooltip
			style: {
				color: '#FFFFFF',  // Color del texto del tooltip
			},
			borderColor: '#197496',  // Color del borde del tooltip
		},
		legend: {
			itemStyle: {
				color: '#a6adbb',
				fontSize: '9px',
			},
			itemHoverStyle: {
				color: '#768093'
			},
			maxHeight: 40,
			navigation: {
				enabled: true,
			},
		},
	})

	const downloadData = async () => {
		axios.get(`/api/information/natural-associated-gas-production`).then((response) => {
			setData(response.data);
		});
	}

	useEffect(() => {
		if (!data || data.length == 0) return

		const deposits: Deposits = {}

		data.forEach((item) => {
			if (!deposits[item.id_area_yacimiento]) {
				deposits[item.id_area_yacimiento] = []
			}

			deposits[item.id_area_yacimiento].push({
				area_yacimiento: item.area_yacimiento,
				anio: item.anio,
				mes: item.mes,
				diaria: parseFloat(parseFloat(item.diaria).toFixed(2))
			})
		});

		if (Object.keys(deposits).length == 0) return

		//sort by date
		Object.keys(deposits).forEach((key) => {
			deposits[key].sort((a, b) => {
				if (a.anio === b.anio) {
					return a.mes - b.mes
				}

				return a.anio - b.anio
			})
		})

		//only las 16 months
		Object.keys(deposits).forEach((key) => {
			deposits[key] = deposits[key].slice(-16)
		})

		const series: SeriesOptionsType[] = []

		Object.keys(deposits).forEach((key) => {
			series.push({
				name: deposits[key][0].area_yacimiento,
				data: deposits[key].map((item) => item.diaria),
				type: 'area',
				stacking: 'normal'
			})
		})

		const categories = deposits[Object.keys(deposits)[0]].map((item) => `${item.anio} - ${item.mes}`)

		setOptions({
			...options,
			xAxis: {
				categories: categories,
				labels: {
					style: {
						color: 'currentColor'
					}
				}
			},
			series: series
		})
	}, [data])

	useEffect(() => {
		downloadData()
	}, [])

	return (
		<div className={props.wFull ? styles.wFull : ''}>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}

			/>
		</div>
	)
}