import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Modal } from 'react-daisyui';

export interface Filter {
	id: string;
	label: string;
	value: string; // Nuevo campo para el valor del filtro
}

interface FilterProps {
	filters: Filter[];
	onFilterChange: (selectedFilters: { [key: string]: string }) => void;
	onModalClose?: () => void;
}

const Filter: React.FC<FilterProps> = ({ filters, onFilterChange }) => {
	const ref = useRef<HTMLDialogElement>(null);
	const [filterValues, setFilterValues] = useState<{ [key: string]: string }>(
		() => filters.reduce((acc, filter) => {
			acc[filter.id] = filter.value;
			return acc;
		}, {} as { [key: string]: string })
	);

	useEffect(() => {
		const newFilterValues = filters.reduce((acc, filter) => {
			acc[filter.id] = filter.value;
			return acc;
		}, {} as { [key: string]: string });
		setFilterValues(newFilterValues);
	}, [filters]);

	const handleButtonClick = () => {
		ref.current?.showModal();
	};

	const handleInputChange = (id: string, value: string) => {
		const newFilterValues = { ...filterValues, [id]: value };
		setFilterValues(newFilterValues);
		onFilterChange(newFilterValues);
	};

	return (
		<div>
			<Button color="ghost" onClick={handleButtonClick} style={{ backgroundColor: '#13171a' }}>
				<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M440-160q-17 0-28.5-11.5T400-200v-240L168-736q-15-20-4.5-42t36.5-22h560q26 0 36.5 22t-4.5 42L560-440v240q0 17-11.5 28.5T520-160h-80Zm40-308 198-252H282l198 252Zm0 0Z" /></svg>
				Filtros
			</Button>
			<Modal ref={ref} backdrop={true}>
				<form method="dialog">
					<Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }}>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
					</Button>
				</form>
				<Modal.Header className="font-bold">Filtros</Modal.Header>
				<Modal.Body>
					{filters.map(filter => (
						<div className="form-control w-full max-w-xs" key={filter.id} style={{ marginBottom: '8px' }}>
							<label className="label">
								<span className="label-text">{filter.label}</span>
							</label>
							<Input
								type="text"
								value={filterValues[filter.id] || ''}
								onChange={(e) => handleInputChange(filter.id, e.target.value)}
							/>
						</div>
					))}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Filter;