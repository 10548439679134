import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Swal from 'sweetalert2';

// Create a new instance of axios
const instance: AxiosInstance = axios.create({});

// Add a response interceptor
instance.interceptors.response.use(
	(response: AxiosResponse) => {
		// You can modify the response data here (e.g., transform, filter, etc.)
		return response;
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 401 || error.response.status === 403) {
				Swal.fire({
					heightAuto: false,
					icon: "error",
					title: "Autenticación inválida o vencida",
				}).then(() => {
					location.reload();
				});
			}

			if (error.response.status === 500) {
				Swal.fire({
					heightAuto: false,
					icon: "error",
					title: "Error interno del servidor",
				});
			}
		}
		return Promise.reject(error);
	}
);

export default instance;