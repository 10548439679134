import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table } from 'react-daisyui';
import styles_main from './Main.module.scss';

type MainOperatorsNQNData = {
	anio: number,
	mes: number,
	id_empresa: string,
	empresa: string,
	cantidad: string,
	mes_actual: string,
	share: number,
	mes_anterior: string,
	anio_movil: string,
}

export default function MainOperatorsNQN() {
	const [data, setData] = useState<Array<MainOperatorsNQNData>>([])

	const downloadData = async () => {
		axios.get(`/api/information/main-operators-nqn`).then((response) => {
			const data: Array<MainOperatorsNQNData> = response.data;

			if (!data || data.length === 0) return;

			const total = data.reduce((total, d) => total + parseFloat(d.cantidad), 0)
			const daysInMonth = new Date(data[0].anio, data[0].mes, 0).getDate();

			data.forEach(d => {
				const share = parseFloat(d.mes_actual) / (total / daysInMonth / 1000);
				d.share = share * 100;
			})

			setData(data);
		});
	}

	useEffect(() => {
		downloadData()
	}, [])

	return (
		<div className={styles_main.wrapper}>
			<h3>Principales operadores NQN</h3>
			<div className={styles_main.table}>
				<Table zebra pinRows size="sm">
					<Table.Head className="th_acciones">
						<span>Empresa</span>
						<span>Actual</span>
						<span>Share</span>
						<span>vs n-1</span>
						<span>vs 12a</span>
					</Table.Head>
					<Table.Body>
						{data && data.map((d, index) => (
							<Table.Row hover key={index}>
								<span>{d.empresa}</span>
								<span>{parseFloat(d.mes_actual).toFixed(2)}</span>
								<span>{d.share && d.share.toFixed(2)}%</span>
								<span>{(parseFloat(d.mes_actual) - parseFloat(d.mes_anterior)).toFixed(2)}</span>
								<span>{(parseFloat(d.mes_actual) - parseFloat(d.anio_movil)).toFixed(2)}</span>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</div>
		</div>
	)
}