import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Modal, Select } from 'react-daisyui';

export interface Order {
	id: string;
	label: string;
	value: 'ASC' | 'DESC'; // Campo para el valor del orden
	priority: number; // Campo para la prioridad del orden
}

interface OrderProps {
	orders: Order[];
	onOrderChange: (selectedOrders: { [key: string]: 'ASC' | 'DESC' }) => void;
	onPriorityChange: (updatedOrders: Order[]) => void;
	onModalClose?: () => void;
}

const Order: React.FC<OrderProps> = ({ orders, onOrderChange, onPriorityChange }) => {
	const ref = useRef<HTMLDialogElement>(null);
	const [orderValues, setOrderValues] = useState<{ [key: string]: 'ASC' | 'DESC' }>(
		() => orders.reduce((acc, order) => {
			acc[order.id] = order.value;
			return acc;
		}, {} as { [key: string]: 'ASC' | 'DESC' })
	);
	const [orderPriorities, setOrderPriorities] = useState<{ [key: string]: number }>(
		() => orders.reduce((acc, order) => {
			acc[order.id] = order.priority;
			return acc;
		}, {} as { [key: string]: number })
	);

	useEffect(() => {
		const newOrderValues = orders.reduce((acc, order) => {
			acc[order.id] = order.value;
			return acc;
		}, {} as { [key: string]: 'ASC' | 'DESC' });
		setOrderValues(newOrderValues);

		const newOrderPriorities = orders.reduce((acc, order) => {
			acc[order.id] = order.priority;
			return acc;
		}, {} as { [key: string]: number });
		setOrderPriorities(newOrderPriorities);
	}, [orders]);

	const handleButtonClick = () => {
		ref.current?.showModal();
	};

	const handleSelectChange = (id: string, value: 'ASC' | 'DESC') => {
		const newOrderValues = { ...orderValues, [id]: value };
		setOrderValues(newOrderValues);
		onOrderChange(newOrderValues);
	};

	const handlePriorityChange = (id: string, priority: number) => {
		const newOrderPriorities = { ...orderPriorities, [id]: priority };
		setOrderPriorities(newOrderPriorities);
		const updatedOrders = orders.map(order => ({
			...order,
			priority: newOrderPriorities[order.id]
		}));
		onPriorityChange(updatedOrders);
	};

	return (
		<div>
			<Button onClick={handleButtonClick} style={{ backgroundColor: '#13171a' }}>
				<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m196-376-23 70q-4 11-14 18.5t-22 7.5q-20 0-32.5-16.5T100-333l120-321q5-12 15-19t23-7h30q13 0 23 7t15 19l121 323q7 19-4.5 35T411-280q-12 0-22-7.5T375-306l-25-70H196Zm24-68h104l-48-150h-6l-50 150Zm418 92h166q15 0 25.5 10.5T840-316q0 15-10.5 25.5T804-280H572q-10 0-17-7t-7-17v-38q0-7 2-13.5t7-11.5l193-241H592q-15 0-25.5-10.5T556-644q0-15 10.5-25.5T592-680h222q10 0 17 7t7 17v38q0 7-2 13.5t-7 11.5L638-352ZM384-760q-7 0-9.5-6t2.5-11l89-89q6-6 14-6t14 6l89 89q5 5 2.5 11t-9.5 6H384Zm82 666-89-89q-5-5-2.5-11t9.5-6h192q7 0 9.5 6t-2.5 11l-89 89q-6 6-14 6t-14-6Z" /></svg>
				Ordenar
			</Button>
			<Modal ref={ref} backdrop={true}>
				<form method="dialog">
					<Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }}>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
					</Button>
				</form>
				<Modal.Header className="font-bold">Ordenar</Modal.Header>
				<Modal.Body>
					{orders
						.sort((a, b) => a.priority - b.priority)
						.map(order => (
							<div key={order.id} style={{ marginBottom: '8px' }}>
								<label className="label">
									<span className="label-text">{order.label}</span>
								</label>
								<div className="flex items-center">
									<Select
										value={orderValues[order.id] || 'ASC'}
										onChange={(e) => handleSelectChange(order.id, e.target.value as 'ASC' | 'DESC')}
									>
										<option value="ASC">ASC</option>
										<option value="DESC">DESC</option>
									</Select>
									<Input
										type="number"
										value={orderPriorities[order.id]}
										onChange={(e) => handlePriorityChange(order.id, parseInt(e.target.value))}
										style={{ marginLeft: '8px', width: '100%' }}
									/>
								</div>
							</div>
						))}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Order;