
import Swal from "sweetalert2";
import axios from '../../../api/axios';
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { Button, Loading, Modal, Table, Select, Alert, Input } from "react-daisyui";
import { Company, Deposit, Owner } from '../interface';
import styles from './Owners.module.scss';

type OwnersProps = {
	deposit: Deposit | null;
}

const Owners = forwardRef((props: OwnersProps, ref: ForwardedRef<HTMLDialogElement>) => {
	const [companies, setCompanies] = useState<Array<Company>>([]);
	const [owners, setOwners] = useState<Array<Owner>>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const [company, setCompany] = useState<string>('');
	const [percentage, setPercentage] = useState<string>('');

	const downloadOwners = async () => {
		axios.get(`/api/deposits/${props.deposit?.reference}/owners`).then(response => {
			setOwners(response.data.data);
		});
	}

	const downloadCompanies = async () => {
		axios.get("/api/companies").then(response => {
			setCompanies(response.data.data);
		});
	}

	useEffect(() => {
		downloadCompanies();
		downloadOwners();
	}, [props.deposit])

	const save = () => {
		setLoading(true);

		axios(`/api/deposits/${props.deposit?.reference}/owners`, {
			method: 'POST',
			data: owners
		}).then(() => {
			Swal.fire({
				heightAuto: false,
				icon: 'success',
				title: '¡Listo!',
				text: 'Los propietarios han sido guardados correctamente.'
			});
		}).finally(() => {
			setLoading(false);

			if (ref && 'current' in ref && ref.current) {
				ref.current.close();
			}
		});
	};

	return (
		<Modal ref={ref} backdrop={true} className={styles.modal}>
			<form method="dialog">
				<Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }}>
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
				</Button>
			</form>
			<Modal.Header className="font-bold">{props.deposit?.name}</Modal.Header>
			<Modal.Body>
				{loading ? <Loading /> :
					<div>
						{owners.reduce((acc, owner) => acc + parseFloat(owner.porcentaje), 0) !== 100 &&
							<Alert className={styles.alert} icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#00b6ff"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>}>
								<span>La suma de los porcentajes no es 100%.</span>
							</Alert>
						}

						<div className={styles.agregar_empresa}>
							<div className="form-control w-full max-w-xs">
								<label className="label">
									<span className="label-text">Agregar</span>
								</label>
								<Select value={company} onChange={(e) => setCompany(e.target.value)} size="sm">
									<option value="">Seleccionar empresa</option>
									{companies.map((company, index) => (
										<option key={index} value={company.reference}>{company.name}</option>
									))}
								</Select>
							</div>
							<div className="form-control w-full max-w-xs">
								<label className="label">
									<span className="label-text">Porcentaje</span>
								</label>
								<Input type="number" value={percentage} onChange={(e) => setPercentage(e.target.value)} size="sm" />
							</div>
							<Button size="sm" color="primary" onClick={() => {
								if (!company || !percentage) {
									Swal.fire({
										heightAuto: false,
										icon: 'error',
										title: '¡Error!',
										text: 'Debe seleccionar una empresa y un porcentaje.'
									});
									return;
								}

								if (!props.deposit?.reference) {
									Swal.fire({
										heightAuto: false,
										icon: 'error',
										title: '¡Error!',
										text: 'El yacimiento no tiene referencia.'
									});
									return;
								}

								const newOwners = [...owners];

								newOwners.push({
									id_area_yacimiento: props.deposit?.reference,
									id_empresa: company,
									empresa: companies.find(c => c.reference == company)?.name || '',
									porcentaje: percentage
								});

								setOwners(newOwners);
								setCompany('');
								setPercentage('');
							}}>Agregar</Button>
						</div>
						<Table zebra pinRows>
							<Table.Head className="th_acciones">
								<span>Empresa</span>
								<span>Porcentaje</span>
								<span>Eliminar</span>
							</Table.Head>
							<Table.Body>
								{owners.map((owner, index) => (
									<Table.Row hover key={index}>
										<span>{owner.empresa}</span>
										<span><Input size="sm" type="number" value={owner.porcentaje} onChange={(e) => {
											const newOwners = [...owners];
											newOwners[index].porcentaje = e.target.value;
											setOwners(newOwners);
										}} /></span>
										<span>
											<Button size="sm" shape="square" color="error" onClick={() => {
												const newOwners = [...owners];
												newOwners.splice(index, 1);
												setOwners(newOwners);
											}}>
												<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
											</Button>
										</span>
									</Table.Row>
								))}
							</Table.Body>
						</Table>

					</div>
				}
			</Modal.Body>
			<Modal.Actions>
				<Button color="success" fullWidth onClick={save}>Guardar</Button>
			</Modal.Actions>
		</Modal>
	)
});

export default Owners;
