import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table } from 'react-daisyui';
import styles_main from './Main.module.scss';

type MainAssociatedGasDepositsData = {
	anio: number,
	mes: number,
	id_empresa: string,
	empresa: string,
	id_area_yacimiento: string,
	area_yacimiento: string,
	mes_actual: string,
	mes_anterior: string,
	anio_movil: string,
}

export default function MainAssociatedGasDeposits() {
	const [data, setData] = useState<Array<MainAssociatedGasDepositsData>>([])

	const downloadData = async () => {
		axios.get(`/api/information/main-associated-gas-deposits`).then((response) => {
			setData(response.data);
		});
	}

	useEffect(() => {

	}, [data])

	useEffect(() => {
		downloadData()
	}, [])

	return (
		<div className={styles_main.wrapper}>
			<h3>Principales Yacimientos Gas Asociado</h3>
			<div className={styles_main.table}>
				<Table zebra pinRows size="sm">
					<Table.Head className="th_acciones">
						<span>Empresa</span>
						<span>Yacimiento</span>
						<span>Actual</span>
						<span>vs n-1</span>
						<span>vs 12a</span>
					</Table.Head>
					<Table.Body>
						{data && data.map((d, index) => (
							<Table.Row hover key={index}>
								<span>{d.empresa}</span>
								<span>{d.area_yacimiento}</span>
								<span>{parseFloat(d.mes_actual).toFixed(2)}</span>
								<span>{(parseFloat(d.mes_actual) - parseFloat(d.mes_anterior)).toFixed(2)}</span>
								<span>{(parseFloat(d.mes_actual) - parseFloat(d.anio_movil)).toFixed(2)}</span>
							</Table.Row>
						))}
					</Table.Body>
					<Table.Footer>
						<span>Total</span>
						<span></span>
						<span>{data.reduce((total, d) => total + parseFloat(d.mes_actual), 0).toFixed(2)}</span>
						<span>{data.reduce((total, d) => total + parseFloat(d.mes_actual) - parseFloat(d.mes_anterior), 0).toFixed(2)}</span>
						<span>{data.reduce((total, d) => total + parseFloat(d.mes_actual) - parseFloat(d.anio_movil), 0).toFixed(2)}</span>
					</Table.Footer>
				</Table>
			</div>
		</div>
	)
}