
import styles from './Companies.module.scss';
import Swal from "sweetalert2";
import axios from '../../api/axios';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useRef, useState } from "react";
import { Button, Input, Loading, Modal, Pagination, Select, Table, Tooltip } from "react-daisyui";
import { Company } from './interface';


export default function Companies() {
	const [companies, setCompanies] = useState<Array<Company>>([]);
	const [company, setCompany] = useState<Company | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [saveLoading, setSaveLoading] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(20)
	const [page, setPage] = useState(1)

	const [name, setName] = useState<string>('');
	const [reference, setReference] = useState<string>('');

	const detailModal = useRef<HTMLDialogElement>(null);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		const totalPages = Math.ceil(total / limit);
		const maxPage = Math.min(totalPages, maxPageButtons);
		const middlePage = Math.ceil(maxPage / 2);
		const startPage = Math.max(1, page - middlePage);
		const endPage = Math.min(totalPages, startPage + maxPage - 1);

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(
				<Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
					{i}
				</Button>
			);
		}

		return pageNumbers;
	}

	const downloadCompanies = async () => {
		setLoading(true);

		axios.get("/api/companies?offset=" + (page - 1) * limit + "&limit=" + limit + (search ? "&filters[search]=" + search : '')).then(response => {
			setCompanies(response.data.data);
			setTotal(response.data.total);
		}).finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		downloadCompanies();
	}, [page, limit, search]);

	const editCompany = (company: Company) => {
		setCompany(company);
		detailModal.current?.showModal();

		setName(company.name);
		setReference(company.reference);
	};

	const deleteCompany = (company: Company) => {
		Swal.fire({
			heightAuto: false,
			icon: 'warning',
			title: '¿Estás seguro?',
			text: 'Esta acción no se puede deshacer.',
			showCancelButton: true,
			confirmButtonText: 'Sí, eliminar',
			cancelButtonText: 'Cancelar',
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);

				axios.delete(`/api/companies/${company.id}`).then(() => {
					Swal.fire({
						heightAuto: false,
						icon: 'success',
						title: '¡Listo!',
						text: 'La empresa ha sido eliminada correctamente.'
					});
				}).finally(() => {
					setLoading(false);
					downloadCompanies();
				});
			}
		});
	}

	const save = () => {
		setSaveLoading(true);

		axios(`/api/companies${company && company.id ? `/${company.id}` : ''}`, {
			method: company && company.id ? 'PUT' : 'POST',
			data: {
				...company,
				name,
				reference
			}
		}).then(() => {
			Swal.fire({
				heightAuto: false,
				icon: 'success',
				title: '¡Listo!',
				text: 'La empresa ha sido guardada correctamente.'
			});
		}).finally(() => {
			setSaveLoading(false);

			detailModal.current?.close();

			setCompany(null);
			downloadCompanies();
		});
	}

	const debounced = useDebouncedCallback((value) => {
		setSearch(value);
	}, 500);

	return (
		<>
			<div className={styles.companies_wrapper}>
				<div className={styles.actions}>
					<Input className='w-full' placeholder="Buscar" size="sm" onChange={(e) => debounced(e.target.value)} />
					<Button color="primary" size='sm' onClick={() => detailModal.current?.showModal()}>Agregar empresa</Button>
				</div>

				<div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					<div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
						{loading ? <div className={styles.loading}><Loading color="primary" /></div> :
							<Table zebra pinRows>
								<Table.Head className="th_acciones">
									<span>Nombre</span>
									<span>Referencia</span>
									<span>Acciones</span>
								</Table.Head>
								<Table.Body>
									{companies.map((company, index) => (
										<Table.Row hover key={index}>
											<span>{company.name}</span>
											<span>{company.reference}</span>
											<span className="flex gap-2">
												<Tooltip message="Editar">
													<Button size="sm" shape="square" color="primary" onClick={() => editCompany(company)}>
														<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
													</Button>
												</Tooltip>
												<Tooltip message="Eliminar">
													<Button size="sm" shape="square" color="error" onClick={() => deleteCompany(company)}>
														<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
													</Button>
												</Tooltip>
											</span>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						}
					</div>
				</div>
				<div className={styles.pagination}>
					<div className={styles.cantidad}>
						<small >Cantidad por página:</small>
						<Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={20}>20</option>
						</Select>
					</div>
					<Pagination className="flex justify-center">
						<Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
							Anterior
						</Button>
						{renderPageNumbers()}
						<Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
							Siguiente
						</Button>
					</Pagination>
				</div>
			</div>
			<Modal ref={detailModal} backdrop={true}>
				<form method="dialog">
					<Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }}>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
					</Button>
				</form>
				<Modal.Header className="font-bold">{name}</Modal.Header>
				<Modal.Body>
					{saveLoading ? <Loading color='primary' /> : <>
						<div className="form-control w-full max-w-xs" style={{ marginBottom: '16px' }}>
							<label className="label">
								<span className="label-text">Nombre</span>
							</label>
							<Input value={name} onChange={(e) => setName(e.target.value)} />
						</div>
						<div className="form-control w-full max-w-xs">
							<label className="label">
								<span className="label-text">Referencia</span>
							</label>
							<Input value={reference} onChange={(e) => setReference(e.target.value)} />
						</div>
					</>}
				</Modal.Body>
				<Modal.Actions>
					<Button color="success" fullWidth onClick={save}>Guardar</Button>
				</Modal.Actions>
			</Modal>
		</>
	)
}