
import 'react-js-cron/dist/styles.css'

import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Button, Input, Pagination, Select, Table, Tooltip } from "react-daisyui";
import styles from './Trabajos.module.scss';
import Cron from "react-js-cron";
import axios from '../../api/axios';
type Job = {
	id: number;
	name: string;
	cron: string;
	code: string;
}

export default function Trabajos() {
	const ref = useRef<HTMLDialogElement>(null);

	const navigate = useNavigate();

	const [jobs, setJobs] = useState<Array<Job>>([]);
	const [job, setJob] = useState<Job | null>(null);
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(10)
	const [page, setPage] = useState(1)

	const [name, setName] = useState<string>('');
	const [cron, setCron] = useState<string>('');

	const [isOpen, setIsOpen] = useState(false);
	const closeModal = () => setIsOpen(false);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		const totalPages = Math.ceil(total / limit);
		const maxPage = Math.min(totalPages, maxPageButtons);
		const middlePage = Math.ceil(maxPage / 2);
		const startPage = Math.max(1, page - middlePage);
		const endPage = Math.min(totalPages, startPage + maxPage - 1);

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(
				<Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
					{i}
				</Button>
			);
		}

		return pageNumbers;
	}

	const downloadJobs = async () => {
		axios.get("/api/jobs?offset=" + (page - 1) * limit + "&limit=" + limit).then((response) => {
			setJobs(response.data.data);
			setTotal(response.data.total);
		});
	}

	useEffect(() => {
		downloadJobs();
	}, [page, limit])

	const editJob = (job: Job) => {
		setJob(job);
		setName(job.name);
		setCron(job.cron);
		setIsOpen(true);
		//ref.current?.showModal();
	};

	const runJob = (job: Job) => {
		Swal.fire({
			heightAuto: false,
			icon: "question",
			title: "¿Desea ejecutar este trabajo?",
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: "Si, ejecutar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (!result.isConfirmed) return;

			fetch(`/api/jobs/${job.id}/execute`, {
				method: 'POST'
			});

			Swal.fire({
				heightAuto: false,
				icon: "info",
				title: "Ejecutando trabajo",
			}).then(() => {
				navigate(`/trabajos/${job.id}`);
			});

		});
	}

	const save = () => {
		fetch(`/api/jobs${job ? `/${job.id}` : ''}`, {
			method: job ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				name,
				cron
			})
		}).then((res) => {
			if (!res.ok) throw new Error('Error al ejecutar el trabajo');

			downloadJobs();
			ref.current?.close();
		}).catch(() => {
			Swal.fire({
				heightAuto: false,
				icon: "error",
				title: "Error al guardar el trabajo",
			});
		});
	}


	return (
		<>
			<div className={styles.trabajos_wrapper}>
				<div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					<div>
						<Table zebra pinRows>
							<Table.Head className="th_acciones">
								<span>Nombre</span>
								<span>Cron</span>
								<span>Acciones</span>
							</Table.Head>

							<Table.Body>
								{jobs && jobs.map((job, index) => (
									<Table.Row hover key={index}>
										<span>{job.name}</span>
										<span>{job.cron}</span>
										<span className="flex gap-2">
											<Tooltip message="Ejecutar">
												<Button size="sm" shape="square" color="accent" onClick={() => runJob(job)}>
													<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Zm80-207Zm0 134 210-134-210-134v268Z" /></svg>
												</Button>
											</Tooltip>
											<Tooltip message="Editar">
												<Button size="sm" shape="square" color="secondary" onClick={() => editJob(job)}>
													<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
												</Button>
											</Tooltip>
											<Tooltip message="Historial">
												<Link to={`/trabajos/${job.id}`}>
													<Button size="sm" shape="square" color="primary">
														<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-120q-126 0-223-76.5T131-392q-4-15 6-27.5t27-14.5q16-2 29 6t18 24q24 90 99 147t170 57q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h70q17 0 28.5 11.5T360-600q0 17-11.5 28.5T320-560H160q-17 0-28.5-11.5T120-600v-160q0-17 11.5-28.5T160-800q17 0 28.5 11.5T200-760v54q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm40-376 100 100q11 11 11 28t-11 28q-11 11-28 11t-28-11L452-452q-6-6-9-13.5t-3-15.5v-159q0-17 11.5-28.5T480-680q17 0 28.5 11.5T520-640v144Z"/></svg>
													</Button>
												</Link>
											</Tooltip>
										</span>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</div>
				</div>
				<div className={styles.pagination}>
					<div className={styles.cantidad}>
						<small >Cantidad por página:</small>
						<Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={20}>20</option>
						</Select>
					</div>
					<Pagination className="flex justify-center">
						<Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
							Anterior
						</Button>
						{renderPageNumbers()}
						<Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
							Siguiente
						</Button>
					</Pagination>
				</div>
			</div>

			{isOpen &&
				<div className={styles.custom_modal_overlay}>
					<div className={styles.custom_modal}>
						<div className={styles.custom_modal_header}>
							<h2>Editar trabajo</h2>
							<button onClick={closeModal} className={styles.close_button}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg></button>
						</div>
						<div className={styles.custom_modal_body}>
							<div className={styles.cron_container}>
								<Input placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} />
								<Cron value={cron} setValue={setCron} />
							</div>
						</div>
						<div className={styles.custom_modal_actions}>
							<Button color="success" fullWidth onClick={save}>Guardar</Button>
						</div>
					</div>
				</div>
			}
		</>
	)
}