import styles from './Main.module.scss'

import { useEffect, useRef, useState } from 'react';

import Highcharts from "highcharts/highstock";
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting';
import axios from '../../../api/axios';

// Inicializar el módulo de exportación
if (typeof Highcharts === 'object') {
	HighchartsExporting(Highcharts);
}

type NaturalGasProductionData = {
	anio: number,
	mes: number,
	cantidad: string,
	diaria: string,
}

interface NaturalGasProductionProps {
	wFull?: boolean
}

export default function NaturalGasProduction(props: NaturalGasProductionProps) {
	Highcharts.setOptions({
		lang: {
			months: [
				'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
				'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
			],
			shortMonths: [
				'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
				'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
			],
			decimalPoint: ",",
			loading: 'Cargando...',
			viewFullscreen: "Ver en pantalla completa",
			printChart: "Imprimir gráfico",
			downloadPNG: "Descargar imagen PNG",
			downloadJPEG: "Descargar imagen JPEG",
			downloadPDF: "Descargar documento PDF",
			downloadSVG: "Descargar imagen SVG",
		}
	})

	const ref = useRef<HighchartsReactRefObject>(null);

	const [data, setData] = useState<Array<NaturalGasProductionData>>([])
	const [options, setOptions] = useState<Highcharts.Options>({
		colors: ['#197496'],
		chart: {
			backgroundColor: '#252b33',
		},
		accessibility: {
			enabled: false
		},
		title: {
			text: 'Producción de gas natural',
			align: 'left',
			style: {
				color: 'currentColor',
				font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
			}
		},
		xAxis: {
			labels: {
				style: {
					color: '#a6adbb',
				},
			},
		},
		yAxis: {
			title: {
				text: 'Millones de m3 por día',
				style: {
					color: 'currentColor',
					font: 'bold 14px "Trebuchet MS", Verdana, sans-serif'
				}
			},
			labels: {
				style: {
					color: 'currentColor'
				}
			}
		},
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true,
					inside: true,
					verticalAlign: 'top',
				}
			}
		},
		tooltip: {
			backgroundColor: '#333333',
			style: {
				color: '#FFFFFF',
			},
			borderColor: '#197496',
		},
		legend: {
			itemStyle: {
				color: '#a6adbb',

			},
			itemHoverStyle: {
				color: '#768093'
			}
		},
	})

	const downloadData = async () => {
		axios.get(`/api/information/natural-gas-production`).then((response) => {
			setData(response.data);
		});
	}

	useEffect(() => {
		const series = data.map((item) => [
			Date.UTC(item.anio, item.mes - 1),
			parseFloat(parseFloat(item.diaria).toFixed(2))
		])

		setOptions({
			...options,
			series: [{
				type: 'column',
				name: 'Producción',
				data: series,
			}],
		})

		if (ref.current) {
			ref.current.chart?.update({
				rangeSelector: {
					selected: 0,
					buttons: [
						{
							type: 'month',
							count: 6,
							text: '6m'
						},
						{
							type: 'year',
							count: 1,
							text: '1y'
						},
						{
							type: 'month',
							count: 18,
							text: '18m'
						},
						{
							type: 'all',
							text: 'All'
						}
					]
				}
			})
		}
	}, [data])

	useEffect(() => {
		downloadData()
	}, [])

	return (
		<div className={props.wFull ? styles.wFull : ''}>
			<HighchartsReact
				highcharts={Highcharts}
				constructorType={"stockChart"}
				options={options}
				ref={ref}
			/>
		</div>
	)
}