// authContext.js
import { createContext, useState, useContext, useEffect } from 'react';

interface User {
	sub: string;
	name: string;
	preferred_username: string;
	given_name: string;
	family_name: string;
	email: string;
	realm_access: {
		roles: string[];
	}
}

// Definir la interfaz para el contexto de autenticación
interface AuthContextType {
	isAuthenticated: boolean;
	user: User | null;
	loading: boolean;
	me: () => Promise<User | null>;
	login: () => void;
	logout: () => void;
	account: () => void;
	canAccess: (roles: string[]) => boolean;
}

// Crear el contexto de autenticación
const AuthContext = createContext<AuthContextType | undefined>(undefined);

type Props = {
	children: React.ReactNode;
};

// Proveedor de autenticación
export const AuthProvider = ({ children }: Props) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const [accountURL, setAccountURL] = useState(null);
	const [loading, setLoading] = useState(true);

	// Función para obtener la información
	const me = async () => {
		const response = await fetch('/api/auth/user', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		});

		if (response.status === 200) {
			const data = await response.json();
			setAccountURL(data.account_url);
			return data.userinfo;
		} else {
			return null;
		}
	}

	// Función para iniciar sesión
	const login = async () => {
		const user = await me();
		if (!user) {
			return location.href = '/api/auth/login';
		}

		setIsAuthenticated(true);
		setUser(user);
		setLoading(false);
	};

	// Función para cerrar sesión
	const logout = () => {
		setIsAuthenticated(false);
		setUser(null);

		location.href = '/api/auth/logout';
	};

	// Función para cerrar sesión
	const account = () => {
		if (!accountURL) return;
		location.href = accountURL;
	};

	const canAccess = (roles: string[]) => {
		if (!user) return false;
		const userRoles = user.realm_access.roles;
		return roles.some(role => userRoles.includes(role));
	}

	// Verificar el estado de autenticación al cargar el componente
	useEffect(() => {
		login();
	}, []);

	return (
		<AuthContext.Provider value={{ isAuthenticated, user, loading, me, login, logout, account, canAccess }}>
			{children}
		</AuthContext.Provider>
	);
};
// Hook para usar el contexto de autenticación
export const useAuth = (): AuthContextType => {
	const authContext = useContext(AuthContext);
	if (!authContext) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return authContext;
};
