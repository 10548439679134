import { Badge, Button, Checkbox, Dropdown, Pagination, Select, Table } from "react-daisyui";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import styles from '../Fuentes.module.scss'
import styles_table from './Tables.module.scss'
import Filter, { Filter as FilterInterface } from "../../../components/filter/Filter";
import Order, { Order as OrderInterface } from "../../../components/order/Order";

type Gas = {
	id: number,
	anio: number,
	mes: number,
	id_empresa: string
	empresa: string,
	id_area_permiso_concesion: string,
	area_permiso_concesion: string,
	id_area_yacimiento: string,
	area_yacimiento: string,
	id_cuenca: string,
	id_provincia: string,
	provincia: string,
	id_ubicacion: number,
	ubicacion: string,
	id_concepto: number,
	concepto: string,
	cantidad: number,
	observaciones: string,
	desc_mes: string,
	fecha_data: string,
}

const columns = [
	"id", "anio", "mes", "empresa", "area_permiso_concesion",
	"area_yacimiento", "cuenca", "provincia", "ubicacion",
	"concepto", "cantidad", "observaciones", "desc_mes", "fecha_data"
];

const columnNames: { [key: string]: string } = {
	id: "ID",
	anio: "Año",
	mes: "Mes",
	empresa: "Empresa",
	area_permiso_concesion: "Área Permiso Concesión",
	area_yacimiento: "Área Yacimiento",
	cuenca: "Cuenca",
	provincia: "Provincia",
	ubicacion: "Ubicación",
	concepto: "Concepto",
	cantidad: "Cantidad",
	observaciones: "Observaciones",
	desc_mes: "Mes",
	fecha_data: "Fecha"
};

export default function Gas() {
	const [gas, setGas] = useState<Array<Gas>>([])
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(20)
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false);
	const [visibleColumnsGas, setVisibleColumnsGas] = useState<Array<string>>(columns);

	const [filters, setFilters] = useState<Array<FilterInterface>>([
		{ id: "anio", label: "Año", value: "" },
		{ id: "desc_mes", label: "Mes", value: "" },
		{ id: "empresa", label: "Empresa", value: "" },
		{ id: "area_yacimiento", label: "Área Yacimiento", value: "" },
		{ id: "provincia", label: "Provincia", value: "" },
		{ id: "concepto", label: "Concepto", value: "" },
		{ id: "observaciones", label: "Observaciones", value: "" }
	])

	const [orders, setOrders] = useState<Array<OrderInterface>>([
		{ id: "anio", label: "Año", value: "DESC", priority: 1 },
		{ id: "mes", label: "Mes", value: "DESC", priority: 2 },
		{ id: "empresa", label: "Empresa", value: "DESC", priority: 3 },
		{ id: "area_yacimiento", label: "Área Yacimiento", value: "DESC", priority: 4 },
		{ id: "provincia", label: "Provincia", value: "DESC", priority: 5 },
		{ id: "concepto", label: "Concepto", value: "DESC", priority: 6 },
		{ id: "catidad", label: "Cantidad", value: "DESC", priority: 7 },
	])

	const buildFiltersQuery = () => {
		const query = filters
			.filter(filter => filter.value)
			.map(filter => `filters[${filter.id}]=${encodeURIComponent(filter.value)}`)
			.join("&");
		return query;
	}

	const buildOrdersQuery = () => {
		const query = orders
			.filter(order => order.value)
			.map(order => `orders[${order.priority}][${order.id}]=${order.value}`)
			.join("&");
		return query;
	}

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		const totalPages = Math.ceil(total / limit);
		const maxPage = Math.min(totalPages, maxPageButtons);
		const middlePage = Math.ceil(maxPage / 2);
		const startPage = Math.max(1, page - middlePage);
		const endPage = Math.min(totalPages, startPage + maxPage - 1);

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(
				<Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
					{i}
				</Button>
			);
		}

		return pageNumbers;
	}

	const downloadCSV = () => {
		window.open("/api/gas/csv", "_blank");
	}

	useEffect(() => {
		const downloadGas = async () => {
			setLoading(true);

			axios.get("/api/gas?offset=" + (page - 1) * limit + "&limit=" + limit + "&" + buildFiltersQuery() + "&" + buildOrdersQuery()).then((response) => {
				setGas(response.data.data);
				setTotal(response.data.total);
			}).finally(() => setLoading(false));
		}

		downloadGas();
	}, [page, limit, filters, orders])

	useEffect(() => {
		const updateVisibleColumnsGas = () => {
			const width = window.innerWidth;
			if (width < 768) {
				setVisibleColumnsGas(columns.slice(0, 5));
			} else {
				setVisibleColumnsGas(columns);
			}
		};

		updateVisibleColumnsGas();
		window.addEventListener("resize", updateVisibleColumnsGas);

		return () => window.removeEventListener("resize", updateVisibleColumnsGas);
	}, [columns]);


	const handleColumnSelection = (column: string) => {
		if (visibleColumnsGas.includes(column)) {
			setVisibleColumnsGas(visibleColumnsGas.filter(c => c !== column));
		} else {
			setVisibleColumnsGas([...visibleColumnsGas, column]);
		}
	};

	return (
		<div className={styles_table.tables_wrapper}>
			<div className={styles_table.tables_filter}>
				<div className={styles.filterCount}>
					<Badge color="primary" outline>Filtros aplicados: {filters.filter(f => f.value).length}</Badge>
				</div>
				<Dropdown>
					<Dropdown.Toggle className={styles_table.toggle_btn}>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
						Columnas
					</Dropdown.Toggle>
					<Dropdown.Menu className="w-52">
						{columns.map((col) => (
							<div className={styles_table.tables_filter_item} key={col}>
								<Checkbox
									checked={visibleColumnsGas.includes(col)}
									onChange={() => handleColumnSelection(col)}
									color="primary"
									id={`gas-${col}`}
								/>
								<label htmlFor={`gas-${col}`}>{columnNames[col]}</label>
							</div>
						))}
					</Dropdown.Menu>
				</Dropdown>

				<Filter filters={filters} onFilterChange={(filtersWithValues) => {
					setFilters(filters.map(f => ({ ...f, value: filtersWithValues[f.id] })));
				}} />

				<Order orders={orders} onOrderChange={(ordersWithValues) => {
					setOrders(orders.map(f => ({ ...f, value: ordersWithValues[f.id] })));
				}} onPriorityChange={(updatedOrders) => {
					setOrders(updatedOrders);
				}} />

				<Button color="primary" size="md" onClick={downloadCSV} startIcon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-337q-8 0-15-2.5t-13-8.5L308-492q-12-12-11.5-28t11.5-28q12-12 28.5-12.5T365-549l75 75v-286q0-17 11.5-28.5T480-800q17 0 28.5 11.5T520-760v286l75-75q12-12 28.5-11.5T652-548q11 12 11.5 28T652-492L508-348q-6 6-13 8.5t-15 2.5ZM240-160q-33 0-56.5-23.5T160-240v-80q0-17 11.5-28.5T200-360q17 0 28.5 11.5T240-320v80h480v-80q0-17 11.5-28.5T760-360q17 0 28.5 11.5T800-320v80q0 33-23.5 56.5T720-160H240Z" /></svg>}>
					Descargar CSV
				</Button>
			</div>
			<div className={styles_table.overflow}>
				{loading ?
					<div className={styles_table.loading}>Cargando...</div> :
					<Table size="sm" zebra pinRows>
						<Table.Head>
							{visibleColumnsGas.includes("id") && <span>{columnNames["id"]}</span>}
							{visibleColumnsGas.includes("anio") && <span>{columnNames["anio"]}</span>}
							{visibleColumnsGas.includes("mes") && <span>{columnNames["mes"]}</span>}
							{visibleColumnsGas.includes("empresa") && <span>{columnNames["empresa"]}</span>}
							{visibleColumnsGas.includes("area_permiso_concesion") && <span>{columnNames["area_permiso_concesion"]}</span>}
							{visibleColumnsGas.includes("area_yacimiento") && <span>{columnNames["area_yacimiento"]}</span>}
							{visibleColumnsGas.includes("cuenca") && <span>{columnNames["cuenca"]}</span>}
							{visibleColumnsGas.includes("provincia") && <span>{columnNames["provincia"]}</span>}
							{visibleColumnsGas.includes("ubicacion") && <span>{columnNames["ubicacion"]}</span>}
							{visibleColumnsGas.includes("concepto") && <span>{columnNames["concepto"]}</span>}
							{visibleColumnsGas.includes("cantidad") && <span>{columnNames["cantidad"]}</span>}
							{visibleColumnsGas.includes("observaciones") && <span>{columnNames["observaciones"]}</span>}
							{visibleColumnsGas.includes("desc_mes") && <span>{columnNames["desc_mes"]}</span>}
							{visibleColumnsGas.includes("fecha_data") && <span>{columnNames["fecha_data"]}</span>}
						</Table.Head>

						<Table.Body>
							{gas.map((o, i) => (
								<Table.Row hover key={i}>
									{visibleColumnsGas.includes("id") ? <span>{o.id}</span> : <></>}
									{visibleColumnsGas.includes("anio") ? <span>{o.anio}</span> : <></>}
									{visibleColumnsGas.includes("mes") ? <span>{o.mes}</span> : <></>}
									{visibleColumnsGas.includes("empresa") ? <span>{o.empresa}</span> : <></>}
									{visibleColumnsGas.includes("area_permiso_concesion") ? <span>{o.area_permiso_concesion}</span> : <></>}
									{visibleColumnsGas.includes("area_yacimiento") ? <span>{o.area_yacimiento}</span> : <></>}
									{visibleColumnsGas.includes("cuenca") ? <span>{o.id_cuenca}</span> : <></>}
									{visibleColumnsGas.includes("provincia") ? <span>{o.provincia}</span> : <></>}
									{visibleColumnsGas.includes("ubicacion") ? <span>{o.ubicacion}</span> : <></>}
									{visibleColumnsGas.includes("concepto") ? <span>{o.concepto}</span> : <></>}
									{visibleColumnsGas.includes("cantidad") ? <span>{o.cantidad}</span> : <></>}
									{visibleColumnsGas.includes("observaciones") ? <span>{o.observaciones}</span> : <></>}
									{visibleColumnsGas.includes("desc_mes") ? <span><Badge color="accent">{o.desc_mes}</Badge></span> : <></>}
									{visibleColumnsGas.includes("fecha_data") ? <span>{o.fecha_data}</span> : <></>}
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				}
			</div>
			<div className={styles.pagination}>
				<div className={styles.cantidad}>
					<small >Cantidad por página:</small>
					<Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
						<option value={5}>5</option>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={30}>30</option>
						<option value={50}>50</option>
					</Select>
				</div>
				<Pagination className="flex justify-center">
					<Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
						Anterior
					</Button>
					{renderPageNumbers()}
					<Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
						Siguiente
					</Button>
				</Pagination>
			</div>
		</div >
	)
}